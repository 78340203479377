import React from 'react'
import PropTypes from 'prop-types'
import { kebabCase } from 'lodash'
import Helmet from 'react-helmet'
import { graphql, Link } from 'gatsby'
import Layout from '../components/Layout'
import Content, { HTMLContent } from '../components/Content'
import Breadcrumb from '../components/Breadcrumb'
import { Container } from '@material-ui/core';

export const BlogPostTemplate = ({
  date,
  content,
  contentComponent,
  description,
  tags,
  title,
  helmet,
  color,
  imgSrc,
  location
}) => {
  const PostContent = contentComponent || Content

  return (
    <section className="section article blog">
      <Breadcrumb path='/blog/' title={title}/>
      {helmet || ''}
      <Container>
        <div className="content">
          <div className="columns">
            <div className="article-intro">
              <div className="article-mobile-title">
                <h1 className="title">
                  {title}
                </h1>
              </div>
              {imgSrc && imgSrc !== 'none' ? (
                <div className="article-img" style={{backgroundImage: `url(${imgSrc})`}}>
                </div>
              ) : ''}
              <span className="date">Publicerad: {date} </span>
            </div>
            <div className="column">
              <div className="description">
                <h3>{description}</h3>
              </div>
              <PostContent content={content} />
              {tags && tags.length ? (
                <div style={{ marginTop: `4rem` }}>
                  <h4>Tags</h4>
                  <ul className="taglist">
                    {tags.map(tag => (
                      <li key={tag + `tag`}>
                        <Link to={`/tags/${kebabCase(tag)}/`}>{tag}</Link>
                      </li>
                    ))}
                  </ul>
                </div>
              ) : null}
            </div>
          </div>
        </div>
      </Container>
    </section>
  )
}

BlogPostTemplate.propTypes = {
  date: PropTypes.string,
  content: PropTypes.node.isRequired,
  contentComponent: PropTypes.func,
  description: PropTypes.string,
  title: PropTypes.string,
  helmet: PropTypes.object,
  color: PropTypes.string,
  imgSrc: PropTypes.string
}

const BlogPost = ({location, data }) => {
  const { markdownRemark: post } = data

  return (
    <Layout>
      <BlogPostTemplate
        date={post.frontmatter.date}
        content={post.html}
        contentComponent={HTMLContent}
        description={post.frontmatter.description}
        helmet={
          <Helmet titleTemplate="%s | Blog">
            <title>{`${post.frontmatter.title}`}</title>
            <meta
              name="description"
              content={`${post.frontmatter.description}`}
            />
          </Helmet>
        }
        tags={post.frontmatter.tags}
        title={post.frontmatter.title}
        color={post.frontmatter.color}
        imgSrc={post.frontmatter.featuredimage ? post.frontmatter.featuredimage.childImageSharp.fluid.src : 'none'}
        location={location}
      />
    </Layout>
  )
}

BlogPost.propTypes = {
  data: PropTypes.shape({
    markdownRemark: PropTypes.object,
  }),
}

export default BlogPost

export const pageQuery = graphql`
  query BlogPostByID($id: String!) {
    markdownRemark(id: { eq: $id }) {
      id
      html
      frontmatter {
        date(formatString: "YYYY-MM-DD", locale: "sv")
        title
        description
        tags
        color
        featuredimage {
          childImageSharp {
            fluid(maxWidth: 800, quality: 100) {
              ...GatsbyImageSharpFluid
            }
          }
        }
      }
    }
  }
`
